<template>
  <div>
    <el-card>
      <el-form ref="formref" label-width="120px">
        <el-form-item label="角色名称">
          <el-col :span="8">
            <el-input v-model="form_1.title"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-col :span="8">
            <el-input v-model="form_1.description"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="分配权限">
          <el-tree
            :data="rightslist"
            :props="treeprops"
            show-checkbox
            node-key="id"
            default-expand-all
            check-strictly
            :default-checked-keys="form_1.rules"
            ref="treeRefq"
            @check-change="checkChange"
            @check="getCurrentNode"
          ></el-tree>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="allotrights" v-if="id"
            >修改</el-button
          >
          <el-button type="primary" @click="allotrightsadd" v-if="!id"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form_1: {
        title: '',
        description: '',
        rules: []
      },
      rightslist: [],

      treeprops: {
        label: 'label',
        children: 'children'
      },
      id: this.$route.query.id,
      roleid: ''
    }
  },
  methods: {
    getCurrentNode(data) {
      let node = this.$refs.treeRefq.getNode(data)
      this.childNodes(node)
      this.parentNodes(node)
      //是否编辑的表示
    },
    childNodes(node) {
      let len = node.childNodes.length
      for (let i = 0; i < len; i++) {
        node.childNodes[i].checked = node.checked
        this.childNodes(node.childNodes[i])
      }
    },
    parentNodes(node) {
      if (node.parent) {
        for (let key in node) {
          if (key == 'parent') {
            node[key].checked = true
            this.parentNodes(node[key])
          }
        }
      }
    },
    getCheckedNodes() {
      let parentArr = this.$refs.treeRefq.getHalfCheckedKeys()
      let childeArr = this.$refs.treeRefq.getCheckedKeys()
      let arr = [...parentArr, ...childeArr]
      // this.form_1.rules = arr
      this.arr = arr
      console.log(this.arr)
    },
    getrole() {
      this.$http.get('/admin/AuthMenu/getList').then(({ data: res }) => {
        // 所有权限的数据
        this.rightslist = res.data
        // console.log(this.rightslist)
      })
    },
    async allotrights() {
      this.getCheckedNodes()

      this.form_1.id = this.id
      const { data: res } = await this.$http.post(`/admin/AuthGroup/edit`, {
        ...this.form_1,
        rules: this.arr.join(',')
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        // this.getrelelist()
        this.$router.push('/system_auth_group')
        this.setrightdialog = false
      } else {
        this.$message.error(res.message)
      }
    },
    async allotrightsadd() {
      this.getCheckedNodes()
      const { data: res } = await this.$http.post(`/admin/AuthGroup/add`, {
        ...this.form_1,
        rules: this.arr.join(',')
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        // location.reload()
        this.$router.push('/system_auth_group')

        this.setrightdialogs = false
      } else {
        this.$message.error(res.message)
      }
    },
    show() {
      if (this.id) {
        this.form_1.id = this.id
        this.$http
          .get('/admin/AuthGroup/getInfo?id=' + this.id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.form_1 = {
                ...res.data,
                rules: res.data.rules.split(',')
              }
            } else {
              this.$message.error(res.message)
            }
          })
        this.$http.get('/admin/AuthMenu/getList').then(({ data: res }) => {
          // 所有权限的数据
          this.rightslist = res.data
          console.log(this.rightslist)
        })
        // this.getlefkey(role, this.defkey)
      }
    },
    checkChange(a, b, c) {
      const anode = this.$refs.treeRefq.getNode(a)
      // console.log(a, b, c)
      !anode.checked ? this.findchildren(anode.parent) : ''
      // console.log(keys, keysq)
      // console.log(keys)
    },
    findchildren(current) {
      const fnode = this.$refs.treeRefq.getNode(current)
      if (!fnode.isLeaf) {
        fnode.indeterminate = true
        fnode.checked = true
      }
    }
  },
  created() {
    this.getrole()
    this.show()
  }
}
</script>

<style lang="less" scoped></style>
